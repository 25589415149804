// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-lekoarts-gatsby-theme-cara-templates-cara-tsx": () => import("./../src/@lekoarts/gatsby-theme-cara/templates/cara.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-cara-templates-cara-tsx" */),
  "component---src-pages-classroom-tsx": () => import("./../src/pages/classroom.tsx" /* webpackChunkName: "component---src-pages-classroom-tsx" */),
  "component---src-pages-connected-cannabis-retail-tsx": () => import("./../src/pages/connected-cannabis-retail.tsx" /* webpackChunkName: "component---src-pages-connected-cannabis-retail-tsx" */),
  "component---src-pages-consultant-tsx": () => import("./../src/pages/consultant.tsx" /* webpackChunkName: "component---src-pages-consultant-tsx" */),
  "component---src-pages-data-collection-outreach-events-tsx": () => import("./../src/pages/data-collection-outreach-events.tsx" /* webpackChunkName: "component---src-pages-data-collection-outreach-events-tsx" */),
  "component---src-pages-ecommerce-tsx": () => import("./../src/pages/ecommerce.tsx" /* webpackChunkName: "component---src-pages-ecommerce-tsx" */),
  "component---src-pages-promotional-games-tsx": () => import("./../src/pages/promotional-games.tsx" /* webpackChunkName: "component---src-pages-promotional-games-tsx" */),
  "component---src-pages-reservations-tsx": () => import("./../src/pages/reservations.tsx" /* webpackChunkName: "component---src-pages-reservations-tsx" */),
  "component---src-pages-tablelimit-tsx": () => import("./../src/pages/tablelimit.tsx" /* webpackChunkName: "component---src-pages-tablelimit-tsx" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

